<!-- <nav class="navbar navbar-expand-sm bg-primary navbar-dark">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a routerLink="registration" routerLinkActive="active" class="nav-link">Card Registration</a>
        </li>
        <li class="nav-item">
            <a routerLink="activation" routerLinkActive="active" class="nav-link">Card Activation</a>
        </li>
        <li class="nav-item">
            <a routerLink="sessionend" routerLinkActive="active" class="nav-link">Card Session End</a>
        </li>
        <li class="nav-item">

          <div class="dropDownDiv" >
            <a 
              #noDrop
              style="cursor: pointer" 
              class="nav-link dropBtn" 
              data-toggle="dropdown" 
              routerLinkActive="s"
              (click)="dropFunction()" >Reports</a>
            <div class="dropdown-menu" id="myDropDown" >
              <div class="dropdown-item">
                <a routerLink="summary" >Report Summary</a>  
              </div>
              <div class="dropdown-item">
              <a routerLink="sales" > Sales Collection</a> 
              </div>
          </div>
          </div>

        </li>
    </ul>
</nav>
<h1 class="text-center">{{title}}</h1>
<div class="container">
    <router-outlet></router-outlet>
</div>
  
  
<footer class="footer">
    <div class="container">
        <span>IMS Software Pvt. Ltd.</span><br>
        <span>Baba Krishna Plaza, 1st floor, 44600</span>
    </div>
</footer>
 -->

 <body class="body h-100 w-100">
    <router-outlet></router-outlet>    
 </body>
 
