import { Component, OnInit, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Activate } from 'src/app/interfaces/IMainService';
import { MainService } from 'src/app/services/main.service';
import { PrintInvoiceComponent } from 'src/app/testprint/print-invoice.component';
import { PrintTopComponent } from 'src/app/testprint/print-top.component';
import QRious from 'qrious';
import { stat } from 'fs';
@Component({
  selector: 'app-card-activation',
  templateUrl: './card-activation.component.html',
  styleUrls: ['./card-activation.component.css'],
  providers: [PrintInvoiceComponent, PrintTopComponent],
})
export class CardActivationComponent implements OnInit {
  popup: boolean = false;
  showFonepay: boolean = false;
  message: string;
  top: boolean = false;
  act: boolean = true;
  actBtn: string = 'Activate';
  tmessage = false;
  isError = false;
  trnStatus:string;
  trnStatusColor:string;
  traceId:string;
  guid:string;

  // @Output() myoutput: EventEmitter<string>=new EventEmitter();
  // ostring:"hello bro";
  cardActivateObj = <Activate>{};

  @ViewChild('activationForm') activationForm: NgForm;

  @HostListener('window:keydown', ['$event']) keydownHandle(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
    }
  }

  constructor(
    private router: Router,
    public mainService: MainService,
    public invoicePrint: PrintInvoiceComponent,
    public topPrint: PrintTopComponent
  ) {}

  ngOnInit(): void {}

  onActivate(trnMode: string) {
    if (trnMode == 'cash') {
      if (!confirm('You are going to activate/topup selected card. Do you want to proceed?'))
        return;
    }
    this.cardActivateObj.payMode = trnMode;
    this.cardActivateObj.traceId = this.traceId;
    this.mainService.activate(this.cardActivateObj).subscribe(
      (res: any) => {
        //this.router.navigate(['/body/sessionend']);
        this.message = 'Card successfully activated';
        this.popup = true;
        this.isError = false;

        this.print();
        setTimeout(() => {          
          this.activationForm.reset();
        }, 1500);
      },

      (err) => {
        this.message = 'Error! Card has not been registered yet';
        this.popup = true;
        this.isError = true;

        setTimeout(() => {
          this.activationForm.reset();
        }, 1500);
      }
    );

    console.log();
    this.onPdf();
    this.tmessage = false;
  }

  onKeydown(event) {
    if (event.key === 'Enter' || event.key == 'Tab') {
      this.mainService.cardInfo(this.cardActivateObj.cardNo).subscribe(
        (res: any) => {
          if (res.result.cardvalue != 0) {
            this.cardActivateObj.cardvalue = res.result.cardvalue;
            this.cardActivateObj.cardHolderName = res.result.cardHolderName;
            this.cardActivateObj.cardHolderMobile = res.result.cardHolderMobile;
            this.cardActivateObj.cardHolderExtraInfo =
              res.result.cardHolderExtraInfo;

            this.top = true;
            this.act = false;
            this.actBtn = 'TopUp';

            document.getElementById('cTopUp')?.focus();
            this.tmessage = false;
          } else {
            this.cardActivateObj.cardvalue = res.result.cardvalue;
            this.cardActivateObj.cardvalue = 0;
            this.cardActivateObj.cardHolderName = '';
            this.cardActivateObj.cardHolderMobile = '';
            this.cardActivateObj.cardHolderExtraInfo = '';
            this.top = false;
            this.act = true;
            document.getElementById('cTopUp')?.focus();
            this.actBtn = 'Activate';
            this.tmessage = true;
          }
        },
        (err) => {
          if (err.status == 400) {
            this.message = 'No Card ID found';
            this.popup = true;
            this.onClear();
          }
        }
      );
    }

    if (this.cardActivateObj.cardNo == null) {
      this.onClear();
    }
  }

  onKeydownTU(event) {
    if (event.keyCode == 13) {
      if (this.top == false) {
        document.getElementById('cHolder')?.focus();
      } else {
        document.getElementById('cRemarks')?.focus();
      }
    }
  }

  onKeydownH(event) {
    if (event.keyCode == 13) {
      document.getElementById('cHM')?.focus();
    }
  }

  onKeydownHM(event) {
    if (event.keyCode == 13) {
      document.getElementById('cHX')?.focus();
    }
  }

  onKeydownHX(event) {
    if (event.keyCode == 13) {
      document.getElementById('cRemarks')?.focus();
    }
  }

  onKeyMarks(event) {
    if (event.keyCode == 13) {
      this.onActivate('cash');
    }
  }

  onPdf() {
    this.mainService.cardObj = this.cardActivateObj;
    if (this.act == true) {
      // this.router.navigate(['/body/act']);
      // this.myoutput.emit(this.ostring);
    } else {
      // this.router.navigate(['/body/top']);
    }
  }

  onClear() {
    this.cardActivateObj.cardNo = '';
    this.cardActivateObj.cardvalue = 0;
    this.cardActivateObj.topUpAmount = 0;
    this.cardActivateObj.cardHolderName = '';
    this.cardActivateObj.cardHolderMobile = '';
    this.cardActivateObj.cardHolderExtraInfo = '';
    this.cardActivateObj.remarks = '';

    this.top = false;
    this.act = true;
    this.actBtn = 'Activate';
    this.tmessage = false;
  }

  print() {
    if (this.act == true) {
      setTimeout(() => {
        this.invoicePrint.printInvoice(
          this.cardActivateObj.cardNo,
          this.cardActivateObj.cardHolderName,
          this.cardActivateObj.cardHolderMobile,
          this.cardActivateObj.cardvalue,
          this.cardActivateObj.topUpAmount
        );
      }, 1500);
    } else {
      setTimeout(() => {
        this.topPrint.printInvoice(
          this.cardActivateObj.cardNo,
          this.cardActivateObj.cardHolderName,
          this.cardActivateObj.cardHolderMobile,
          this.cardActivateObj.cardvalue,
          this.cardActivateObj.topUpAmount
        );
      }, 1500);
    }
  }

  closeFonepay(){
    this.showFonepay=false;
    this.trnStatus='';
  }

  requestFonepayQr() {
    if (!confirm('You are going to activate/topup selected card. Do you want to proceed?'))
        return;
    this.showFonepay = true;
    const requestDto = {
      amount: this.cardActivateObj.topUpAmount,
    };

    this.mainService.getFonepayQr(requestDto).subscribe((res: any) => {
      if (res.status == 'ok') {
        this.guid = res.result.guid;
        const socket = new WebSocket(res.result.url2);

        // Event handler for incoming messages
        socket.addEventListener('message', (event) => {
          console.log(event.data);
          console.log(this.trnStatus);
          var response = JSON.parse(event.data);
          var status = JSON.parse(response.transactionStatus);
          if ((this.trnStatus || '') === '') this.trnStatus = status.message;
          else if (this.trnStatus == 'VERIFIED') {
            if (status.success == true) {
              this.trnStatus = 'Payment Confirmed.';
              this.traceId = '#REF ' + status.traceId;
              setTimeout(() => {
                this.onActivate('Fonepay');
                this.closeFonepay();
              }, 2000);
            } else {
              this.trnStatus = 'Payment Cancelled.';
            }
          }
        });

        // Event handler for WebSocket errors
        socket.addEventListener('error', (event) => {
          console.error('WebSocket error:', event);
        });

        var qr = new QRious({
          element: document.getElementById('qr'),
          value: res.result.qrMessage,
        });
        qr.size = 200;
      }
    });  
  }

  checkTrnStatus(){
    const requestDto = {
      guid: this.guid
    };
    this.mainService.checkTrnStatus(requestDto).subscribe((res: any) => {
      if (res.status == 'ok') {
        if(res.result.statusCode == 2){
          this.trnStatusColor = "red";
        }
        else if(res.result.statusCode == 0){
          this.trnStatusColor = "orange";
        }
        this.trnStatus = res.result.paymentStatus;
        this.traceId = res.result.traceId;
      }
    });
  }
}
