<div class="toaster toaster-success" *ngIf="dialogData && dialogData.message && dialogData.type === 'success'">
  <p class="text-success mb-0">{{dialogData?.message}}</p>
</div>

<div class="toaster toaster-danger" *ngIf="dialogData && dialogData.message && dialogData.type === 'danger'">
  <p class="text-danger mb-0">{{dialogData?.message}}</p>
</div>

<div class="toaster toaster-warning" *ngIf="dialogData && dialogData.message && dialogData.type === 'warning'">
  <p class="text-warning mb-0">{{dialogData?.message}}</p>
</div>
