<nav class="navbar navbar-expand-sm d-flex bg-danger navbar-dark">
    <div class="w-100 d-flex ">
        <ul class="navbar-nav w-100">
            <li class="nav-item" *ngIf="mainService.userPrivilages.cardRegister">
                <a routerLink="registration" routerLinkActive="active" class="nav-link">Card Registration</a>
            </li>
            <li class="nav-item" *ngIf="mainService.userPrivilages.cardActivate">
                <a routerLink="activation" routerLinkActive="active" class="nav-link">Card Activation</a>
            </li>
            <li class="nav-item" *ngIf="mainService.userPrivilages.cardClose">
                <a routerLink="sessionend" routerLinkActive="active" class="nav-link">Card Session End</a>
            </li>
            <li class="nav-item" *ngIf="mainService.isAdmin">
                <a routerLink="users" routerLinkActive="active" class="nav-link">Users</a>
            </li>
            <li class="nav-item" *ngIf="mainService.userPrivilages.reports">

                <div class="dropDownDiv">
                    <a #noDrop style="cursor: pointer" class="nav-link dropBtn" data-toggle="dropdown"
                        routerLinkActive="s" (click)="dropFunction()">Reports</a>
                    <div class="dropdown-menu" id="myDropDown">
                        <div class="dropdown-item" routerLink="act-report" style="cursor: pointer;">
                            <a>Activation/Topup Report</a>
                        </div>
                        <div class="dropdown-item" routerLink="summary" style="cursor: pointer;">
                            <a>Report Summary</a>
                        </div>
                        <div class="dropdown-item" routerLink="sales" style="cursor: pointer;">
                            <a> Sales Collection</a>
                        </div>
                        <div class="dropdown-item" routerLink="status" style="cursor: pointer;">
                            <a> Report Status</a>
                        </div>
                    </div>
                </div>

            </li>

        </ul>

        <div class="profile" id="profileId" (click)="ProfileDropdown = !ProfileDropdown">
            <img class="profile-img" src="./../../assets/user.png" alt="">
            <span style="font-weight: bold; padding-left: 5px; padding-top:5px;">{{userName}}</span>
            <img class="downarrow" src="../../../assets/downarrow.png" alt="">

            <div class="profileDropdown" [class.Showdropdown]="ProfileDropdown">
                <ul>
                    <!-- <li>
                          View
                      </li> -->
                    <li (click)="onLogout()">Sign Out</li>
                    <li id="ChangePasswordBtn" (click)="ChangePassword()">Change Password</li>

                </ul>
            </div>
        </div>
    </div>


</nav>


<!-- <h1 class="text-center">{{title}}</h1> -->

<router-outlet></router-outlet>

<footer class="footer d-flex">
    <div class="container">
        <span>IMS Software Pvt. Ltd.</span><br>
        <span>Baba Krishna Plaza, 1st floor, 44600</span>
    </div>
    <div class="nav-item w-full d-block d-sm-none mx-50">
        <button class="btn btn-dark" value="submit" (click)="onLogout()">Logout</button>
    </div>
</footer>