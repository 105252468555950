import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-topup-report',
  templateUrl: './topup-report.component.html',
  styleUrls: ['./topup-report.component.css']
})
export class TopupReportComponent implements OnInit {
  reportList : any[];
  fdate :Date;
  tdate :Date;
  terminals:any[];
  constructor(private mainService: MainService, private userService:UserService) { }

  ngOnInit(): void {
    this.getTerminals();
  }

  getTerminals() {
    this.userService.getTerminals().subscribe((response: any) => {
      this.terminals = response && response.result ? response.result : [];
    }, err => {
    });
  }

  loadReport(){
    this.mainService.invokePost("GetSalesCollection",{fdate : this.fdate, tdate : this.tdate, clientId :"%" }).subscribe(
      (res:any)=>{
        if(res.status == "ok"){
          this.reportList = res.result;
        }
        console.log(res);
        
      },

      (err) =>{
        console.log(err);
      }
    )
  }
}
