import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Register } from 'src/app/interfaces/IMainService';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-card-registration',
  templateUrl: './card-registration.component.html',
  styleUrls: ['./card-registration.component.css']
})
export class CardRegistrationComponent implements OnInit {

  // cardNo: string;
  // tag: string;
  popup:boolean =false;
  message:string;
  cardRegisterObj = <Register>{};
  isError = false;

  @ViewChild('registrationForm') registrationForm: NgForm;

  constructor(
    private router: Router,
    private mainService: MainService
    ) { }

  ngOnInit(): void {
  }

  onRegister(){
    this.mainService.register(this.cardRegisterObj).subscribe(
      (res)=>{
        //this.router.navigate(['/body/activation']);
        this.message = "Card successfully registered";
        this.popup=true;
        this.isError = false;

        setTimeout(() => {
            this.registrationForm.reset();
        }, 500);
        
      },
      (err) =>{
        this.message = "Error! Card with the given ID has already been registered";
        this.popup=true;
        this.isError = true;
        setTimeout(() => {
          this.registrationForm.reset();
      }, 500);
      }
    )


    }
}

