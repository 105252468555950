
<body>
    <div class="card float-sm-right col-12 col-sm-6 my-sm-2 bg-dark text-white">
        <div class="card-body">
            <h2 class="card-title">Card Registration</h2>
            <br>

            <form #registrationForm="ngForm">
                <div class="form-group row">
                    <div class="col-sm-3 ">
                        <label>Card ID:</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="password" id="pCode" class="form-control " required placeholder="Enter card ID" [(ngModel)]="cardRegisterObj.cardNo" name="cardNo">
                    </div>
                </div>
                <div class="form-group row ">
                    <div class="col-sm-3 ">
                        <label>Tag:</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text " class="form-control " placeholder="Enter tag" [(ngModel)]="cardRegisterObj.tag" name="tag">
                    </div>
                </div>
                <br>
                <div class="form-group row d-flex justify-content-center">
                    <div class="col-sm-6 d-flex justify-content-center">
                        <button class="btn btn-success " value="submit" [disabled]="registrationForm.invalid" (click)="onRegister()">Register</button> &nbsp;&nbsp;
                        <button class="btn btn-info " (click)="registrationForm.resetForm({})">Clear</button>
                    </div>
                </div>
            </form>



        </div>
    </div>
</body>


<div class="overlay " *ngIf="popup ">
    <div class="popup ">
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <label>{{message}}</label>
            </div>
        </div>
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <button [ngClass]=" isError ? 'btn btn-warning' : 'btn btn-primary'  "  (click)="popup=false" style="margin-left: 180px; ">Ok</button>
            </div>
        </div>

    </div>
</div>

<style>
    body {
        background-image: url('../../../assets/pos.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        height: 100vh;
    }
</style>