import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { User } from '../user';
import { HttpHeaders } from '@angular/common/http';
import { Activate, UserPrivilages } from 'src/app/interfaces/IMainService';
import { environment } from 'src/environments/environment';
import { env, getuid } from 'process';
import { stringify } from 'querystring';
import { jwtDecode } from "jwt-decode";
// import  appConfig from 'src/assets/appConfig.json'

@Injectable({
  providedIn: 'root',
})
export class MainService {
  url: string;

  get baseUrl() {
    return localStorage.getItem('apiUrl') || '';
  }

  set baseUrl(value:string){
    localStorage.setItem('apiUrl', value)
  }

  cardObj = <Activate>{};
  // const json = require('src/assets/appConfig.json')

  constructor(private httpClient: HttpClient) {
    /*
    this.httpClient.get('assets/json/data.json').subscribe((res) => {
      this.jsonData = res;
      this.baseURL = this.jsonData.apiURL;
      environment.companyName = this.jsonData.companyName;
      environment.companyAddress = this.jsonData.companyAddress;
    } )
    */
  }

  login(loginObj) {
    return this.httpClient.post<any>(`${this.baseUrl}/Login`, loginObj).pipe(
      map((user: any) => {
        this.token = user.token;
        localStorage.setItem('isLoggedIn', 'true')
        return user;
      })
    );
  }

  changePassowrd(oldPassword: string, newPassword: string) {
    const options = { headers: this.getRequestOption(), method: 'post' };
    return this.httpClient.post<any>(
      `${this.baseUrl}/changePassword`,
      { oldPassword, newPassword },
      options
    );
  }

  refreshToken() {
    return this.httpClient.post<any>(`${this.baseUrl}/api/refreshToken`, {});
  }

  get isLoggedIn(): boolean {
    return localStorage.getItem('isLoggedIn') &&
      this.getAuth.token !== undefined &&
      this.getAuth.token !== '' &&
      this.getAuth.token !== 'undefined'
      ? true
      : false;
  }




  register(registerObj) {
    const options = { headers: this.getRequestOption(), method: 'post' };

    return this.httpClient.post<any>(
      `${this.baseUrl}/RegisterCard`,
      registerObj,
      options
    );
  }

  activate(activateObj) {
    const options = { headers: this.getRequestOption(), method: 'post' };
    return this.httpClient.post<any>(
      `${this.baseUrl}/ActivateCard`,
      activateObj,
      options
    );
  }

  close(sessionendObj) {
    const options = { headers: this.getRequestOption(), method: 'post' };
    return this.httpClient.post<any>(
      `${this.baseUrl}/CloseCard`,
      sessionendObj,
      options
    );
  }

  getFonepayQr(fonepayRequest) {
    const options = { headers: this.getRequestOption(), method: 'post' };
    return this.httpClient.post<any>(
      `${this.baseUrl}/getFonepayQr`,
      fonepayRequest,
      options
    );
  }

  checkTrnStatus(fonepayRequest) {
    const options = { headers: this.getRequestOption(), method: 'post' };
    return this.httpClient.post<any>(
      `${this.baseUrl}/checkTrnStatus`,
      fonepayRequest,
      options
    );
  }

  invokePost(method: string, parameter: any) {
    const options = { headers: this.getRequestOption(), method: 'post' };
    return this.httpClient.post<any>(
      `${this.baseUrl}/${method}`,
      parameter,
      options
    );
  }

  getRequestOption() {
    const headers = new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: this.getAuth.token,
    });
    console.log(this.getAuth.token);
    return headers;
  }

  get getAuth(): any {
    let auth: any = {
      token: this.token,
    };
    return auth;
  }

  get token() : string {
    return localStorage.getItem('token') || '';
  }

  set token(value : string)  {
    localStorage.setItem('token', value);
  }

  get userInfo(): any{
    return jwtDecode(localStorage.getItem('token') || '');
  }

  get user(): string {
    return this.userInfo.nameid;
  }


  get role(): string {
    return this.userInfo.role;
  }

  get userPrivilages(): UserPrivilages {
    console.log(this.userInfo);
    return JSON.parse(this.userInfo.userPrivilages || '');
  }

  get isAdmin() : boolean{
    return this.role === 'Admin';
  }

  logout() {
    var apiUrl = this.baseUrl;
    localStorage.clear();
    localStorage.setItem("apiUrl", apiUrl || '');
  }

  cardInfo(cardNo) {
    const options = { headers: this.getRequestOption(), method: 'get' };
    return this.httpClient.get(
      `${this.baseUrl}/GetCardInfo?cardIdorNo=${cardNo}`,
      options
    );
  }

  // getToken(){
  //   return localStorage.getItem('token')
  // }
}
