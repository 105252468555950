import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-top-report',
  templateUrl: './top-report.component.html',
  styleUrls: ['./top-report.component.css']
})
export class TopReportComponent implements OnInit { 
  
  cardNo: any;
  mobile:any;
  topUp:number;
  balance: number;
  myDate = Date.now();
  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    this.cardNo = this.mainService.cardObj.cardNo;
    this.mobile = this.mainService.cardObj.cardHolderMobile;
    this.balance = this.mainService.cardObj.cardvalue;
    this.topUp = this.mainService.cardObj.topUpAmount;

    setTimeout(() => {  
      // var myWindow = window.open('printableArea', '', 'width=200,height=200');
      var popWindow = window.open("","new","width=288, height=auto" )
        // popWindow.print()  

        window.print()

    }, 2000);
  }

}
