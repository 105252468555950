import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MainService } from "./main.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    protected basePath = 'http://localhost:51448/api';

    public defaultHeaders = new HttpHeaders();


    constructor(protected http: HttpClient, public authService: MainService) {
        this.basePath = this.authService.baseUrl || "";
    }

    public getRoles() {
        return this.http.get(this.basePath + '/getRoles', { headers: this.authService.getRequestOption() });
    }

    public getTerminals() {
        return this.http.get(this.basePath + '/getTerminals', { headers: this.authService.getRequestOption() });
    }

    public getUsers() {
        return this.http.get(this.basePath + '/getUsers', { headers: this.authService.getRequestOption() })
    }

    registerUser(fd): Observable<any> {
        const options = { headers: this.authService.getRequestOption(), method: 'post' };
        return this.http.post(`${this.basePath}/register`, fd, options);
    }

    updateUser(fd): Observable<any> {
        const options = { headers: this.authService.getRequestOption(), method: 'post' };
        return this.http.post(`${this.basePath}/updateUser`, fd, options);
    }

    public resetPassword(userName : string)    {
        return this.http.get(this.basePath + `/resetPassword/${userName}`, { headers : this.authService.getRequestOption()})
    }
}