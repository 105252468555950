import { MainService } from './main.service';
import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  userRole: string;

  constructor(
    private authService: MainService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url, state);
  }

  checkLogin(url: string, state): boolean {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    if (url.endsWith('/users') || url.endsWith('/user-register') || url.endsWith('/user-update')) {
      if (!this.authService.isAdmin) {
        this.router.navigate(['/body/registration']);
        return false;
      }
    }
    console.log(url);
    return true;
  }
}
