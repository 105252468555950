<body>

    <div class="card col-12 col-md-6 float-right my-sm-2 bg-dark text-white">
        <div class="card-body">
            <h2 class="card-title">Card Session End</h2>
            <br>
            <div class="form-group row">
                <div class="col-sm-3 ">
                    <label>Card ID:</label>
                </div>
                <div class="col-sm-9">
                    <input 
                        type="Password" 
                        id="pCode" 
                        class="form-control " 
                        placeholder="Enter card ID" 
                        [(ngModel)]="cardSessionEndObj.cardNo" 
                        name="cardNo" 
                        (keydown)="onKeydown($event)">
                </div>
            </div>
            <div class="form-group row ">
                <div class="col-sm-3 ">
                    <label>Card Balance:</label>
                </div>
                <div class="col-sm-9 ">
                    <input 
                        type="text " 
                        class="form-control " 
                        placeholder="0" 
                        [(ngModel)]="cardSessionEndObj.cardvalue" 
                        name="cardvalue" 
                        disabled>
                </div>
            </div>
            <div class="form-group row ">
                <div class="col-sm-3 ">
                    <label>Card Holder:</label>
                </div>
                <div class="col-sm-9">
                    <input type="text " class="form-control " placeholder="Enter card holder" [(ngModel)]="cardSessionEndObj.cardHolderName" name="cardHolderName" disabled>
                </div>
            </div>
            <div class="form-group row ">
                <div class="col-sm-3 ">
                    <label>Remarks:</label>
                </div>
                <div class="col-sm-9">
                    <textarea id="csHolder" class="form-control" placeholder="Add remarks if any" [(ngModel)]="cardSessionEndObj.remarks" name="remarks"></textarea>
                </div>
            </div>
            <br>
            <div class="form-group row d-flex justify-content-center">
                <div class="col-sm-6 d-flex justify-content-center">
                    <button class="btn btn-success " value="submit" (click)="onClose()">Session End</button> &nbsp;&nbsp;
                    <button class="btn btn-info " (click)="onClear()">Clear</button>
                </div>
            </div>



        </div>
    </div>
</body>

<div class="overlay " *ngIf="popup ">
    <div class="popup ">
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <label>{{message}}</label>
            </div>
        </div>
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <button [ngClass]=" isError ? 'btn btn-warning' : 'btn btn-primary'" (click)="popup=false" style="margin-left: 180px;">Ok</button>
            </div>
        </div>

    </div>
</div>

<style>
    body {
        background-image: url('../../../assets/pos.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        height: 100vh;
    }
</style>