import { Component, OnInit } from '@angular/core';
import { SessionEnd } from 'src/app/interfaces/IMainService';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { PrintSessionendComponent } from 'src/app/testprint/print-sessionend.component';

@Component({
  selector: 'app-card-sessionend',
  templateUrl: './card-sessionend.component.html',
  styleUrls: ['./card-sessionend.component.css'],
  providers: [PrintSessionendComponent]
})
export class CardSessionendComponent implements OnInit {

  popup:boolean =false;
  message:string;
  cardSessionEndObj = <SessionEnd> {};
  isError = false;

  constructor(private router: Router, private mainService: MainService,public sessionendPrint: PrintSessionendComponent) { }

  ngOnInit(): void {
  }

  onClose(){
    this.mainService.close(this.cardSessionEndObj).subscribe(
      (res:any)=> {
        this.message = "Session successfully ended";
        this.popup=true;
        this.isError = false;
        this.print();

        setTimeout(() => {
          this.onClear();  
        }, 1500);
        
    },
    (err) =>{{
        this.message = "Error! Card has not been activated yet";
        this.popup=true;
        this.isError = true;
      }

      

    })
  }

  onKeydown(event){
    if (event.key === "Enter" || event.key == "Tab") {

      
      
    this.mainService.cardInfo(this.cardSessionEndObj.cardNo).subscribe(
      (res:any)=>{
      
        this.cardSessionEndObj.cardvalue = res.result.cardvalue;
        this.cardSessionEndObj.cardHolderName = res.result.cardHolderName;
        document.getElementById('csHolder')?.focus();
        
      },
      (err) => {
        {
          this.message = "Card has not been registered or activated!"
          this.popup = true;  
          this.isError = true;
          this.onClear(); 
        }
      }
      
    )
    }
  }

  onClear(){
    this.cardSessionEndObj.cardNo = '';
    this.cardSessionEndObj.cardvalue = 0;
    this.cardSessionEndObj.cardHolderName = '';
    this.cardSessionEndObj.remarks = '';

  }


  print(){
      setTimeout(() => {
        this.sessionendPrint.printInvoice(this.cardSessionEndObj.cardNo,this.cardSessionEndObj.cardHolderName,this.cardSessionEndObj.cardvalue,this.cardSessionEndObj.remarks)
        }, 1500);
    
  }
}
