import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchData', pure: false })

export class SearchPipe implements PipeTransform {
  transform(data, query: string): any {
    if (data) {
      if (query) {
        return data.filter(item => {
          const searchedQuery = query.replace(/[^A-Z0-9]/ig, '').toLowerCase();
          const comId = (item.COMID) ? (item.COMID).replace(/[^A-Z0-9]/ig, '').toLowerCase() : '';
          const comname = (item.COMNAME) ? (item.COMNAME).replace(/[^A-Z0-9]/ig, '').toLowerCase() : '';
          const comaddress = (item.COMADDRESS) ? (item.COMADDRESS).replace(/[^A-Z0-9]/ig, '').toLowerCase() : '';
          const callingname = (item.CALLINGNAME) ? (item.CALLINGNAME).replace(/[^A-Z0-9]/ig, '').toLowerCase() : '';
          const wareHousephone = item.PHONE ? item.PHONE : '';
          const companyname =(item.CompanyName) ? (item.CompanyName).replace(/[^A-Z0-9]/ig, '').toLowerCase() : '';
          if (
            comId.indexOf(searchedQuery) !== -1 ||
            comname.indexOf(searchedQuery) !== -1 ||
            comaddress.indexOf(searchedQuery) !== -1 ||
            callingname.indexOf(searchedQuery) !== -1 ||
            wareHousephone.indexOf(searchedQuery) !== -1 ||
            companyname.indexOf(searchedQuery) !== -1 
          ) {
            return item;
          }
        });
      } else {
        return data;
      }
    }
  }
}
