<html>
    <head>
        <title> DETAIL REPORT </title>
    </head>
    <body>
        <tr>
            <td></td>
            <td></td>
            <td>DATE: </td>
            <td></td>
        </tr>
        <tr>
            <td>Customer ID: </td>
            <td></td>
        </tr>
        <tr>
            <td>Customer Name: </td>
            <td></td>
        </tr>
        <tr>
            <td>From Date: </td>
            <td></td>
        </tr>
        <tr>
            <td>To Date: </td>
            <td></td>
        </tr>

        <tr>
            <td>
                <thead>
                    SN
                </thead>
            </td>
            <td>
                <thead>
                    Particulars
                </thead>
            </td>
            <td>
                <thead>
                    Balance
                </thead>
            </td>
        </tr>
        <tr></tr>
        <tr>
            <td></td>
            <td>Total Balance</td>
            <td></td>
        </tr>    
    </body>
</html>