import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, of, Subject, switchMap, take, tap, throwError } from 'rxjs';
import { Token } from '@angular/compiler';
import { Router } from '@angular/router';
import { MainService } from './main.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private isRefreshing$ = new BehaviorSubject<boolean>(false);

    constructor(private router: Router, private authService: MainService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let token = sessionStorage.getItem('token');
        if (token) {
            request = this.addAuthHeader(request, token);
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error && error.error && error.error.message){
                    alert(error.error.message);
                    return throwError(error);
                }
                if (error.url?.endsWith('/refreshToken')) {
                    //this.authService.logout();
                    this.router.navigate(['/login']);
                    return of(new HttpResponse({ body: {}, status: 0 }));
                }
                if (error.status === 401 || error.status === 403) {
                    return this.handle401(request, next)
                }
                return throwError(error);
            })
        ) as any;
    }

    addAuthHeader(request: HttpRequest<any>, token?: string): HttpRequest<any> {
        if (!token)
            token = this.authService.token;
        return request.clone({
            setHeaders: {
                Authorization: `${token}`,
                'Content-Type': 'application/json',

            }
        });
    }

    private handle401(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<any> {
        this.router.navigate(['/login']);
                    return of(new HttpResponse({ body: {}, status: 0 }));
        /**
         * If we're not refreshing a token, refresh it
         * and retry the original request.
         
        if (!this.isRefreshing$.getValue()) {
            this.isRefreshing$.next(true);

            return this.authService.refreshToken().pipe(
                tap(({ token }) => {
                    au
                    this.authService.token = token
                    this.isRefreshing$.next(false);
                }),
                switchMap(({ token }) => {
                    console.log({ "switchMap": token });
                    return next.handle(this.addAuthHeader(request, token));
                }),
                catchError((err) => {
                    this.isRefreshing$.next(false);
                    //this.authService.onError();
                    return throwError(err);
                })
            );
        }
        */
        /**
         * If we're already refreshing a token, wait
         * until we get the new one and perform the
         * request with the new Access Token.
         */
        return this.isRefreshing$.pipe(
            filter((is) => !is),
            take(1),
            switchMap(() => {
                return next.handle(this.addAuthHeader(request));
            })
        );
    }
}

// 500 server
// 200 sucess
// 401 expire

