<html style="width: 288px;"  id="tab"  >
    <body>
        
        <div class="container"  >
            <div class="row float-right ">
                DATE: 12/01/2020
            </div>
            <hr>
            <div class="row my-4 ">
                CARD ID: 77
            </div>
            <div class="row">
                CARD HOLDER MOBILE: 9803067188  
            </div>
            <br>
            <div class="row">
                <table class="table table-bordered">
                    <thead>
                        <th>SNO. </th>
                        <th>PARTICULARS  </th>
                        <th>AMOUNT  </th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1
                            </td>
                            <td>
                                PREVIOUS BALANCE
                            </td>
                            <td>
                                10
                            </td>
                        </tr>
                        
                        <tr>
                            <td>

                            </td>
                            <td>
                                TOP UP DATA
                            </td>
                            <td>
                                100
                            </td>
                        </tr>

                        <tr>
                            <td>

                            </td>
                            <td>
                                TOTAL BALANCE
                            </td>
                            <td>
                                110
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr>
            </div>
        </div>

        <!-- <p>
            <button (click)="onPdf()" > PDF </button>
        </p> -->
    </body>
</html>