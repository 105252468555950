declare var $: any;

export class ScrollHelper {
  private classToScrollTo: string = "";

  scrollToFirst(className: string) {
      this.classToScrollTo = className;
  }

  scrollToFirstId(id: string) {
    const elements = document.getElementById(id);
    if (!elements) {
      return;
    }
    elements.scrollIntoView({ behavior: 'smooth' });
  }

  doScroll() {
    if (!this.classToScrollTo) {
      return;
    }
    try {
      const elements = document.getElementsByClassName(this.classToScrollTo);
      if (elements.length === 0) {
        return;
      }
      const element = elements[0] as HTMLElement;
      const topOfElement = element.offsetTop + 100;
      window.scroll({ top: topOfElement, behavior: 'smooth' });
    } finally {
      this.classToScrollTo = "";
    }
  }

  scrollNavigation() {
    if (!this.classToScrollTo) {
      return;
    }
    try {
      const elements = document.getElementsByClassName(this.classToScrollTo);
      if (elements.length === 0) {
        return;
      }
      $('html, body').animate({
        scrollTop: $('.' + this.classToScrollTo).first().offset().top  - 70
      }, 500);
    } finally {
      this.classToScrollTo = "";
    }
  }

}
