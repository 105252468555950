import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MainService } from '../services/main.service';
import { Session } from 'protractor';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css'],
})
export class BodyComponent implements OnInit {
  public userName: string;
  public ProfileDropdown: boolean = false;
  ngOnInit(): void {
    this.userName = this.mainService.user;
  }

  //CHECKING DROP OPEN
  isLogin = false;

  // //ELEMENTREF FOR PREVENTING HIDE ON REPORT CLICK
  // //ELEMENTREF FOR PREVENTING HIDE ON REPORT CLICK

  @ViewChild('noDrop', { static: false })
  noDrop!: ElementRef;

  @HostListener('window:click', ['$event'])
  handleWindowClick(event: MouseEvent) {
    if (!(event.target == this.noDrop.nativeElement))
      document.getElementById('myDropDown')?.classList.remove('show');
  }

  // @HostListener('keydown', ['$event']) onKeyDown49(e) {
  //   if(e.shiftKey && e.keyCode == 49){
  //     console.log('1')
  //     this.router.navigate(['/body/registration'])
  //   }
  //   if(e.shiftKey && e.keyCode == 50){
  //     this.router.navigate(['/body/activation'])
  //   }
  //   if(e.shiftKey && e.keyCode == 51){
  //     this.router.navigate(['/body/sessionend'])
  //   }
  //   if(e.shiftKey && e.keyCode == 52){
  //     this.router.navigate(['/body/summary'])
  //   }
  //   if(e.shiftKey && e.keyCode == 53){
  //     this.router.navigate(['/body/sales'])
  //   }
  //   if(e.shiftKey && e.keyCode == 54){
  //     this.router.navigate(['/body/status'])
  //   }
  // }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public mainService: MainService
  ) {}

  dropFunction() {
    //WHEN USER CLICK REPORTS, TOGGLE BETWEEN SHOW AND HIDE
    document.getElementById('myDropDown')?.classList.toggle('show');
    // this.isOpen = !this.isOpen;
    console.log(this.route);
  }

  onLogout() {
    this.mainService.logout();
    this.router.navigate(['/login']);
  }

  ChangePassword() {
    this.router.navigate(['/changePassword'])
  }
}
