<html id="tab">

<body>

    <div class="container" id="printableArea">
        <div class="row float-right ">
            DATE: {{ myDate | date: 'shortDate' }}
        </div>
        <hr>
        <div class="row my-4 ">
            CARD ID: {{cardNo}}
        </div>
        <div class="row">
            CARD HOLDER MOBILE: {{mobile}}
        </div>
        <br>
        <div class="row">
            <table class="table table-bordered">
                <thead>
                    <th>SNO. </th>
                    <th>PARTICULARS </th>
                    <th>AMOUNT </th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            1
                        </td>
                        <td>
                            PREVIOUS BALANCE
                        </td>
                        <td>
                            {{balance}}
                        </td>
                    </tr>

                    <tr>
                        <td>

                        </td>
                        <td>
                            TOP UP DATA
                        </td>
                        <td>
                            {{topUp}}
                        </td>
                    </tr>

                    <tr>
                        <td>

                        </td>
                        <td>
                            <b>TOTAL BALANCE</b>
                        </td>
                        <td>
                            {{balance+topUp}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr>
        </div>
    </div>

    <!-- <p>
            <button (click)="onPdf()" > PDF </button>
        </p> -->
</body>


</html>
<!-- <app-card-activation (myoutput)="getData($event)"></app-card-activation> -->