import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CardActivationComponent } from '../app/Card/card-activation/card-activation.component';
import { BodyComponent } from './body/body.component';
import { CardRegistrationComponent } from './Card/card-registration/card-registration.component';
import { CardSessionendComponent } from './Card/card-sessionend/card-sessionend.component';
import { LoginComponent } from './login/login.component';
import { ActReportComponent } from './Report/act-report/act-report.component';
import { PrintReportComponent } from './Report/print-report/print-report.component';
import { SalesReportComponent } from './Report/sales-report/sales-report.component';
import { StatusReportComponent } from './Report/status-report/status-report.component';
import { SummaryReportComponent } from './Report/summary-report/summary-report.component';
import { TopReportComponent } from './Report/top-report/top-report.component';
import { TestprintComponent } from './testprint/testprint.component';
import { PSummaryComponent } from './Report/summary-report/pSummary/pSummary.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ChangePasswordComponent } from './changePassword/changePassword.component';
import { UserListComponent } from './users/userList/userList.component';
import { UserRegisterComponent } from './users/user-register/user-register.component';
import { TopupReportComponent } from './Report/topup-report/topup-report.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'changePassword', component: ChangePasswordComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'activation', component: CardActivationComponent, canActivate: [AuthGuardService] },
  { path: 'registration', component: CardRegistrationComponent, canActivate: [AuthGuardService] },
  { path: 'sessionend', component: CardSessionendComponent, canActivate: [AuthGuardService] },
  { path: 'users', component:UserListComponent, canActivate: [AuthGuardService] },
  { path: 'user-register', component:UserRegisterComponent, canActivate: [AuthGuardService] },
  { path: 'user-update', component: UserRegisterComponent, canActivate: [AuthGuardService] },
  { path: 'act-report', component: TopupReportComponent, canActivate: [AuthGuardService] },
  { path: 'summary', component: SummaryReportComponent, canActivate: [AuthGuardService] },
  { path: 'sales', component: SalesReportComponent, canActivate: [AuthGuardService] },
  { path: 'body/login', redirectTo: 'body/registration' },
  { path: 'testprint', component: TestprintComponent, canActivate: [AuthGuardService] },
  {
    path: 'body',
    component: BodyComponent,
    children: [
      { path: 'activation', component: CardActivationComponent, canActivate: [AuthGuardService] },
      { path: 'sessionend', component: CardSessionendComponent, canActivate: [AuthGuardService] },
      { path: 'registration', component: CardRegistrationComponent, canActivate: [AuthGuardService] },
      { path: 'users',  component:UserListComponent, canActivate: [AuthGuardService] },
      { path: 'user-register', component:UserRegisterComponent, canActivate: [AuthGuardService] },
      { path: 'user-update', component: UserRegisterComponent, canActivate: [AuthGuardService] },
      { path: 'act-report', component: TopupReportComponent, canActivate: [AuthGuardService] },
      { path: 'summary', component: SummaryReportComponent, canActivate: [AuthGuardService] },
      { path: 'sales', component: SalesReportComponent, canActivate: [AuthGuardService] },
      { path: 'status', component: StatusReportComponent, canActivate: [AuthGuardService] },
      { path: 'print', component: PrintReportComponent, canActivate: [AuthGuardService] },
      { path: 'act', component: ActReportComponent, canActivate: [AuthGuardService] },
      { path: 'top', component: TopReportComponent, canActivate: [AuthGuardService]},
    ],
  },
  // {path: '', redirectTo: 'login', pathMatch:'full'}
  { path: 'pSummary', component: PSummaryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
