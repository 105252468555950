import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { Customer } from './customer';

@Component({
  selector: 'app-status-report',
  templateUrl: './status-report.component.html',
  styleUrls: ['./status-report.component.css']
})
export class StatusReportComponent implements OnInit {

  reportList: any[];
  searchValue: string;

  constructor(private mainService: MainService) { }

  ngOnInit(): void {
  }

  loadReport() {
    this.mainService.invokePost("GetCardStatement", { cardId: this.searchValue, showDetail: true }).subscribe(
      (res: any) => {
        if (res.status == "ok") {
          this.reportList = res.result;
        }
        console.log(res);

      },

      (err) => {
        console.log(err);
      }
    )
  }

  getFontWeight(row: any) {
    if (row.TYPE == "G") {
      console.log(row);
      return 600;
    }
    else
      return 400;
  }
}
