<div class="header bg-gradient-primary pb-6 pt-4 pt-md-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row">
        <div class="col-xl-12 col-lg-12 pb-3 pt-3">
          <nav class="nav-breadcrumb" aria-label="breadcrumb">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                <a routerLink="/dashboard">
                  <i class="ni ni-tv-2"></i>
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">User List</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--7">
  <div class="row mt-5">
    <div class="col-xl-12">
      <div class="card shadow">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col-12 col-md-7 col-lg-7">
              <button class="btn btn-primary d-flex" routerLink="/body/user-register/">New User</button>
            </div>
            <div class="col-12 col-md-5 col-lg-5 text-right">
              <input type="search" class="form-control" placeholder="Search..." name="filter" [(ngModel)]="filter">
            </div>
          </div>
        </div>
        <div class="preloader lms_loader" *ngIf="isLoader"></div>

        <ng-container *ngIf="!isLoader">

          <div class="table-responsive mb-3">
            <table class="table align-items-center table-flush">
              <thead class="thead-light">
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Roles</th>
                  <th scope="col">Email</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="userList && userList.length > 0">
                  <tr
                    *ngFor="let user of (userList | searchData: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount });let i=index;">
                    <th scope="row">{{i+1}}</th>
                    <!-- <td>
                        <a class="text-capitalize" routerLink="/master/licence/dotnet/{{licence?.CompanyPan}}/{{licence?.CompanyName}}">{{licence?.CompanyName}}</a>
                      </td> -->
                    <td class="text-capitalize">{{user?.uname}}</td>
                    <td class="text-capitalize">{{user?.role}}</td>
                    <td class="text-capitalize">{{user?.email}}</td>
                    <td>
                      <a routerLink="/body/user-update" [state]="user" class="text-info mr-3" style="margin-right: 10px;">
                        <i class="fa fa-edit"></i>
                      </a>
                      <a (click)="resetPassword(user)" class="text-info mr-3" style="margin-right: 10px;">
                        <i class="fa fa-key"></i>
                      </a>
                    </td>                    
                  </tr>
                </ng-container>

                <ng-container *ngIf="userList && (userList | searchData: filter).length === 0">
                  <tr>
                    <td colspan="8">
                      <div class="font-weight-bold">User List is unavailable.</div>
                    </td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </div>

          <div class="pagination" *ngIf="userList && (userList | searchData: filter).length > itemsPerPage">
            <pagination-controls (pageChange)="userPagination($event)"></pagination-controls>
          </div>




        </ng-container>

      </div>
    </div>
  </div>

  <!-- <app-footer></app-footer> -->
</div>