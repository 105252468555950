import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from '../interfaces/IMainService';
import { MainService } from '../services/main.service';
import { dynamicAPI } from 'src/app/services/dynamicAPI.services'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../services/validation.service';

@Component({
  selector: 'app-changePassword',
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.css'],
  providers: [dynamicAPI]
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  popup = false;
  message: string;
  cardLoginObj = <Login>{}

  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private mainService: MainService,
    private service: dynamicAPI,
    private v : ValidationService,
    private formBuilder : FormBuilder
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup(
      {
        oldPassword : new FormControl('',[Validators.required]),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
          this.v.passwordMinLowerCaseLettersValidator(),
        ]),
        newPasswordConfirm: new FormControl(
          '',
          Validators.compose([Validators.required])
        ),
      },
      this.v.passwordMatch('newPassword', 'newPasswordConfirm')
      ); 
    }

  get f() { return this.changePasswordForm.controls; }

  onSubmit(event: any) {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.mainService.changePassowrd(this.f.oldPassword.value, this.f.newPassword.value)
      .subscribe((data) => {
        if (data.status == "ok") {
          this.mainService.login({username : this.mainService.user || '', password: this.f.newPassword.value})
            .subscribe((data) => {
              const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/body/login';
              this.router.navigate([returnUrl])
              this.service.load();
            })
        }
      }
      )
  }


  get passwordControl():AbstractControl {
    return this.f.newPassword;
  }

  get confirmPasswordControl() {
    return this.f.newPasswordConfirm;
  }

  public getPasswordError() {
    const control: AbstractControl = this.passwordControl;
    console.log(this.passwordControl?.errors);
    return this.passwordControl?.hasError('required')
      ? 'Please enter a valid password'
      : control.hasError('minlength')
      ? 'The minimum password length is 8 characters'
      : control.hasError('maxlength')
      ? 'The maximum password length is 32 characters'
      : control.hasError('invalidPasswordMinLowerCaseLetters')
      ? 'The password must consists of lower/upper case & numeric characters.'
      : '';
  }

  public getConfirmPasswordError() {
    const control: AbstractControl = this.confirmPasswordControl;
    return control.hasError('required')
      ? 'Please confirm the  password'
      : control.hasError('passwordMismatch')
      ? 'The passwords do not match'
      : '';
  }

}
