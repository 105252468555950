<div class="header bg-gradient-primary pb-6 pt-4 pt-md-6">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row">
                <div class="col-xl-12 col-lg-12 pb-3 pt-3">
                    <nav class="nav-breadcrumb" aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item">
                                <a routerLink="/dashboard">
                                    <i class="ni ni-tv-2"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">User Registration</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mt--7">
    <div class="row mt-5">
        <div class="col-xl-12">
            <div class="card shadow">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0">User Details</h3>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="errorMessage">
                    <div class="col-12">
                        <div class="alert alert-danger">{{errorMessage}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="successMessage">
                    <div class="col-12">
                        <div class="alert alert-success">{{successMessage}}</div>
                    </div>
                </div>
                <form class="form-horizontal form-material" [formGroup]="form" (ngSubmit)="submit(form.value)"
                    style="padding-left: 20px;" autocomplete="off">
                    <div class="form-group row pt-2">
                        <label for="userName" class="col-md-2 fontweight">User Name</label>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter User Name" id="uName" class="input" [attr.disabled] = "isEdit?true:null"
                                formControlName="uname">

                            <div *ngIf="form.controls['uname'].invalid && (form.controls['uname'].dirty || form.controls['uname'].touched)"
                                class="invalid-feedback d-block">
                                <small *ngIf="form.controls['uname'].errors!.required">User Name is required.</small>
                            </div>

                        </div>
                        <input id="disabled" type="checkbox" *ngIf="isEdit" formControlName="disabled">
                        <label for="disabled" class="col-md-2 fontweight" *ngIf="isEdit">Disabled</label>
                    </div>
                    <div class="form-group row pt-2">
                        <label for="userEmail" class="col-md-2 fontweight">Email</label>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Email Address" id="userEmail" class="input"
                                formControlName="email">

                            <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
                                class="invalid-feedback d-block">
                                <small *ngIf="form.controls['email'].errors!.required">Email is required.</small>
                                <small *ngIf="form.controls['email'].errors!.email">Invalid Email address.</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row pt-2">
                        <label for="userRole" class="col-md-2 fontweight">Role</label>
                        <div class="col-md-4">
                            <select class="input" id="userRole" formControlName="role">
                                <option>Admin</option>
                                <option>Cashier</option>
                            </select>
                            <!--                             
                            <input type="text" placeholder="Enter Role" id="userRole" class="input" onkeydown="return false"
                            formControlName="roles">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of options" [value]="selectedRoles" >
                                    <div (click)="optionClicked($event, option)" >
                                        <mat-checkbox [checked]="option.selected" (change)="toggleSelection(option)" (click)="$event.stopPropagation()">
                                            <h4>{{option.Role}}</h4>
                                        </mat-checkbox>   
                                        <p style="font-style: italic; font-size: small; margin-left: 25px; margin-top: -20px;">{{option.Description}}</p>  
                                    </div>                                    
                                </mat-option>
                            </mat-autocomplete> -->
                        </div>
                    </div>
                    <div class="form-group row pt-2">
                        <label for="terminal" class="col-md-2 fontweight">Terminal</label>
                        <div class="col-md-4">
                            <select class="input" id="terminal" formControlName="terminalId">
                                <option *ngFor="let terminal of terminals" [value]="terminal.terminalId">{{terminal.terminalName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row pt-2">
                        <label for="userRole" class="col-md-2 fontweight">Menus</label>
                        <div class="col-md-9" formGroupName="privilages">
                            <input id="cardRegister" type="checkbox" formControlName="cardRegister">                            
                            <label for="cardRegister" class="col-md-2 fontweight" >Card Register</label>
                            
                            <input id="cardActivate" type="checkbox"  formControlName="cardActivate">
                            <label for="cardActivate" class="col-md-2 fontweight" >Card Activation/Topup</label>     
                            
                            <input id="cardClose" type="checkbox"  formControlName="cardClose">
                            <label for="cardClose" class="col-md-2 fontweight" >Card Session End</label>
                            
                            <input id="reports" type="checkbox"  formControlName="reports">
                            <label for="reports" class="col-md-2 fontweight" >Reports</label>         
                        </div>
                    </div>
                    <div class="form-group row pt-2">
                        <label for="userRole" class="col-md-2 fontweight">Payment Modes</label>
                        <div class="col-md-4" formGroupName="privilages">
                            <input id="fonepay" type="checkbox" formControlName="fonepay">                            
                            <label for="fonepay" class="col-md-2 fontweight" >Fonepay</label>
                            <input id="cash" type="checkbox"  formControlName="cash">
                            <label for="cash" class="col-md-2 fontweight" >Cash</label>                           
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                          <button type="submit" class="btn btn-success" style="width: 70px;margin-right: 15px;" [disabled]="isSubmitted">
                            Save
                            <div class="postloader" *ngIf="isLoader"></div>
                          </button>                    
                          <a routerLink="/body/users/" class="btn btn-info">Back</a>
                        </div>
                      </div>

                </form>
            </div>
        </div>
    </div>
</div>
<!-- <app-footer></app-footer> -->