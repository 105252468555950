import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aprint-top',
  template: `<div id="print"></div>`
})
export class PrintTopComponent{
  today = new Date();
  date = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate();
  time = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds();
  dateTime = this.date + ' ' + this.time;

  constructor() {
   
  }

  

  printInvoice(cardno,name,mobile,balance,topup) {
  console.log("@@printInvoice")

    
          let popupWin;
          let tableData = `<table style='width: 100%;font-size: 11px;font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;border-top: none;border-bottom: none;height: 40%'>`
          let head = this.head(cardno,name,mobile,balance,topup)
          // let body = this.body(invoiceData)
          // let footer = this.footer(invoiceData)
          tableData = tableData + head + `</table>`
          popupWin = window.open('', '_blank', 'top=0,left=0,height=50%,width=50%');
          if (popupWin.outerWidth < screen.availWidth || popupWin.outerHeight < screen.availHeight)
              {
                  popupWin.moveTo(0,0);
                  popupWin.resizeTo(screen.availWidth, screen.availHeight);
              }
          popupWin.document.write(`<html> <head><title></title>
          <style>
          @media print
          {
          table { page-break-after:always;font-family:Arial,sans-serif;}
          } 
          </style></head>
      <body onload="window.print();window.close()">
      ${tableData}
      </body>
      </html>`)

          popupWin.document.close();
      

  
  }





  head(cardno,name,mobile,balance,topup): string {
      var header = ""
      var row = ""
      //Reset arrayIndex for multiple calls

          header = header + `
          <div class="row">
            <h4 style="font-family: Arial, Helvetica, sans-serif;text-align:center">Seamless Business Pvt. Ltd.</h4>
            <p style="font-family: Arial, Helvetica, sans-serif;text-align:center;font-size: 11px;">Lagankhel, Lalitpur</p>
            </div>
            <div class="row">
                <p style="text-align:right">Date: ${this.date}</p>
            </div>
            <div class="row">
                <p>Card Id: ${cardno}</p>
            </div>
            <div class="row">
                <p>Cust. Name: ${name}</p>
            </div>
            <div class="row">
                <p>Cust. Mob: ${mobile}</p>
            </div>
            <hr>
                      <thead>
                      <tr>
                              
                              <td>
                                  Previous Balance
                              </td>
                              <td>
                                  ${balance}
                              </td>
                    </tr>
                    
                  <tr>
                          <td>
                              TopUp Data
                          </td>
                          <td>
                              ${topup}
                          </td>
                  </tr>

                  <tr>
                          <td>
                              <b>Total Balance</b>
                          </td>
                          <td>
                              ${balance+topup}
                          </td>
                  </tr>
         
         
      </thead>
      <style>
      p{
        font-size: 11px;
        font-family: Arial, Helvetica, sans-serif;
      }
      </style>
  `
              return header
  }



  




}
