<body>
    <br>
    <div class="container bg-dark text-white">

        <div class="row form-group">
            <div class="col-sm-3">
                <label class="smallBoldLabel">
                    Card Id
                </label>
                <input type="text" [(ngModel)]="searchValue" id="search"
                    placeholder="Enter card Id" style="width:300px">
            </div>
            <div class="col-sm-3">
                <button class="btn btn-success " (keydown)="loadReport()" (click)="loadReport() ">Load</button>
            </div>
        </div>

        <table class="table table-striped table-dark">
            <thead>
                <th style="width: 100px;">Session Id</th>
                <th style="width: 150px;">Date</th>
                <th>Particulars</th>
                <th style="width: 100px;">Debit</th>
                <th style="width: 100px;">Credit</th>
                <th style="width: 120px;">Balance</th>
                <th style="width: 100px;">User</th>
                <th>Remarks</th>
            </thead>
            <tbody>
                <tr style="height: 28px;" [style.fontWeight]="getFontWeight(r)" [style.fontStyle]="r.TYPE=='I'?'italic':'normal'" *ngFor="let r of reportList; let i=index">
                    <td style="width: 100px;">{{r.sessionId}}</td>
                    <td style="width: 150px;">{{r.trnDate}}</td>
                    <td [style.textIndent]="r.TYPE=='I'?'15px':'0px'">{{r.particulars}}</td>
                    <td style="width: 100px; text-align: right;">{{r.debit}}</td>
                    <td style="width: 100px; text-align: right">{{r.credit}}</td>
                    <td style="width: 120px; text-align: right">{{r.balance}}</td>
                    <td style="width: 100px;">{{r.user}}</td>
                    <td>{{r.remarks}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</body>

<style>
    body {
        background-image: url('../../../assets/pos.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        height: 100vh;
    }
</style>