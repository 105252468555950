import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Role } from '../user-register/user-register.component';
import { ScrollHelper } from 'src/app/services/scroll-helper.service';
import { UserService } from 'src/app/services/user.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './userList.component.html',
  styleUrls: ['./userList.component.scss']
})
export class UserListComponent implements OnInit {
  userList: [];
  isLoader = true;
  itemsPerPage = 10;
  currentPage = 1;
  selectedLicence: any;
  filter: any;
  totalCount: number;
  limit = 20;
  offset = 0;

  constructor(
    public scrollHelper: ScrollHelper,
    public userService: UserService,
    private snackBarService: SnackBarService,
    public title: Title
  ) {
    this.title.setTitle(`User List | License Management System`);
  }

  ngOnInit() {
    this.getInformation(0);
  }

  getInformation(flag) {
    this.userService.getUsers().subscribe((response: any) => {
      this.isLoader = false;
      console.log(response);
      this.userList = response && response.result ? response.result : [];
      /*
      this.userList.forEach((user: any) => {
        user.roles.forEach((role: Role, index) => {
          if (index === 0) {
            user.RoleDisplay = role.Role;
          } else {
            user.RoleDisplay += ', ' + role.Role;
          }
        });
      })
      */
      // this.totalCount = response && response.totalCount ? response.totalCount : this.licenceLists.length;
      // this.itemsPerPage = response && response.count ? response.count : this.licenceLists.length;
    }, err => {
      this.isLoader = false;
      this.totalCount = 0;
      this.itemsPerPage = 20;
      this.userList = [];
    });
  }

  userPagination(event) {
    this.isLoader = true;
    this.currentPage = event;
    const t = this;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      t.isLoader = false;
    }, 500);
  }

  deleteLicence(licence) {
    this.selectedLicence = licence;
  }

  selectionChanged(e) {
    this.getInformation(e.target.value);
  }

  resetPassword(user) {
    if (!confirm(`Do you want to reset password for ${user.username}`))
      return;
    this.userService.resetPassword(user.username).subscribe((response: any) => {
      this.snackBarService.openSnackBar({
        type: 'success',
        message: `Password reset successfull. Temp Password : ${response.result}`
      });
      this.getInformation(null);
    }, error => {
      this.snackBarService.openSnackBar({
        type: 'danger',
        message: error.error.message ? error.error.message : JSON.stringify(error.error)
      });
    });
  }
}
