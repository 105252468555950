import { Component, OnInit } from '@angular/core';
import { report } from 'process';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.css']
})
export class SummaryReportComponent implements OnInit {
  reportList : any[];
  fdate :Date;
  tdate :Date;
  constructor(private mainService: MainService) { }

  ngOnInit(): void {
  }

  loadReport(){
    this.mainService.invokePost("GetSummaryCollection",{fdate : this.fdate, tdate : this.tdate, clientId :"%" }).subscribe(
      (res:any)=>{
        if(res.status == "ok"){
          this.reportList = res.result;
        }
        console.log(res);
        
      },

      (err) =>{
        console.log(err);
      }
    )
  }
}
