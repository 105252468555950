
<body class=" w-100 my-50 ">
    <div class="row justify-content-center ">
        <div 
        class="card container align-items-center col-12 col-sm-12 col-md-8 col-lg-6 col-xl-5" 
        style="background-color: rgb(72, 7, 133);">
        <!-- <div class="card-body "> -->
            <form class="card-form justify-content-around " >
                <h2 class="card-title text-center my-4" style="color: white;">LOGIN</h2>
                <div class="form-group row align-items-center justify-content-center ">
                    <div class=" col-10 mx-2 ">
                        <label style="color: white;">Username:</label>
                    </div>
                    <div class="col-10">
                        <input type="text" id="pCode" class="form-control " placeholder="Enter your username" [(ngModel)]="cardLoginObj.username" name="username">
                    </div>
                </div>
                <div class="form-group row my-6 align-items-center justify-content-center ">
                    <div class=" col-10 mx-2">
                        <label style="color: white;">Password:</label>
                    </div>
                    <div class="col-10">
                        <input type="password" class="form-control " placeholder="Enter your password" [(ngModel)]="cardLoginObj.password" name="password">
                    </div>
                </div>
                <div class="form-group row d-flex justify-content-center">
                    <div class=" d-flex my-4 justify-content-center float-right ">
                        <button class="btn btn-success " type="submit" (click)="onLogin()">LOGIN</button>
                    </div>
                </div>

            </form>

        <!-- </div> -->
    </div>
    </div>
</body>


<div class="overlay" *ngIf="popup">
    <div class="popup">
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <label>{{message}}</label>
            </div>
        </div>
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <button class="btn btn-warning" (click)="popup=false " style="margin-left: 180px; ">Ok</button>
            </div>
        </div>

    </div>
</div>

<style>
    body {
        background-image: url('../../../assets/blue.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        background-size: cover; 
        height: 100%;
        width: 100%;
        background-position: center center;
    }
</style>