import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.css']
})
export class PrintReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onPdf(){
    var stable = document.getElementById('tab')?.innerHTML;

    var style = "<style>"

    style = style + "table {width: 100%;font: 17px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";

    var win = window.open('','','height=700,width=700');

    win?.document.write('<html><head>')
    win?.document.writeln('<title>BILL</title>')
    win?.document.writeln('style')
    win?.document.writeln('</head>')
    win?.document.writeln('<body>')
    win?.document.writeln('stable')
    win?.document.writeln('</body></html>')
      

    win?.document.close();
    win?.print();
  }

}
