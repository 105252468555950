import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { SnackbarComponent } from '../snackbar/snackbar.component';


@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar
  ) {}

  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message
    });
  }

}
