import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { UserService } from 'src/app/services/user.service';

export class Role {
  constructor(public Role: string, public Description: string, public selected?: boolean) {
    if (selected === undefined) selected = false;
  }
}

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {
  form: FormGroup;
  terminals: any[];
  successMessage: string;
  errorMessage: string;
  selectedRoles: Role[] = new Array<Role>();
  role = new FormControl();
  isSubmitting = false;
  isSubmitted = false;
  isLoader = false;
  isEdit = false;

  constructor(public fb: FormBuilder,
    public userService: UserService,
    private snackBarService: SnackBarService,
    public route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit(): void {
    this.forminit();
  }

  forminit() {
    this.form = this.fb.group({
      uname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      disabled: [false],      
      role: [''],
      terminalId: [''],
      privilages : this.fb.group({
        cash: [false],
        fonepay: [false],
        cardRegister: [false],
        cardActivate: [false],
        cardClose: [false],
        reports: [false]
      })
    });
    this.terminals = [];
    this.getTerminals();
  }
  
  
  getTerminals() {
    this.userService.getTerminals().subscribe((response: any) => {
      this.terminals = response && response.result ? response.result : [];
      this.loadValues();
      console.log(this.isEdit);
    }, err => {
    });
  }

  loadValues() {
    console.log('@loadValues')
    this.route.paramMap
      .pipe(map(() => window.history.state)).subscribe(res => {
        if (res.uname) {
          /*
          this.options.forEach((role) => {
            if (res.roles.map(x=>x.Role).indexOf(role.Role) >= 0) {
              role.selected = true;
              this.selectedRoles.push(role);
            }
          })
          */
          this.form.patchValue({
            uname: res.uname,
            email: res.email,
            role: res.role,
            disabled: res.disabled,
            privilages : res.privilages,            
            terminalId: res.terminalId,
          });
          //this.form.controls["roles"].setValue(this.selectedRoles.map(x => x.Role));
          this.isEdit = true;
        }
      })
  }

  submit(formValue) {
    if (!this.form.valid) {
      this.isSubmitting = true;
      return;
    }
    this.isSubmitted = true;
    this.isLoader = true;

    let url;
    if (this.isEdit)
      url = this.userService.updateUser(formValue);
    else
      url = this.userService.registerUser(formValue);
      
    url.subscribe((response: any) => {
      this.isSubmitted = false;
      this.isLoader = false;
      this.resetForm();
      this.router.navigate(['/body/users']);
      // if (this.mode === 'update') { this.router.navigate(['/rent-heading']); }
      const successData = {
        type: 'success',
        message: response && response.message ? response.message : `Registration Successfull`
      };
      this.snackBarService.openSnackBar(successData);
    }, error => {
      this.isSubmitted = false;
      this.isLoader = false;
      if (error && error.error) {
        this.errorMessage = error.error.message ? error.error.message : JSON.stringify(error.error);
      }
      const newThis = this;
      setTimeout(() => {
        newThis.errorMessage = "";
      }, 5000);
    });
  }

  resetForm() {
    this.form.reset();
  }

  displayFn(value: Role[] | string): string | undefined {
    let displayValue: string = "";
    if (Array.isArray(value)) {
      value.forEach((role, index) => {
        if (index === 0) {
          displayValue = role.Role;
        } else {
          displayValue += ', ' + role.Role;
        }
      });
    } else {
      displayValue = value;
    }
    console.log(displayValue, value, Array.isArray(value));
    return displayValue;
  }
  toggleSelection(user: Role) {
    console.log(user);
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedRoles.push(user);
    } else {
      const i = this.selectedRoles.findIndex(value => value.Role === user.Role);
      this.selectedRoles.splice(i, 1);
    }

    this.form.controls["roles"].setValue(this.selectedRoles);
  }

  optionClicked(event: Event, user: Role) {
    event.stopPropagation();
    this.toggleSelection(user);
  }
}
