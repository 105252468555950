<body class="h-100">
    <div class="card float-sm-right col-12 col-md-6 bg-dark text-white mx-auto mb-5 ">
        <div class="card-body">
            <h2 class="card-title">Card Activation</h2>
            <br>

            <form #activationForm="ngForm" >
                <div class="form-group row ">
                    <div class="col-sm-3 ">
                        <label>Card ID:</label>
                    </div>
                    <div class="col-sm-9">
                        <input 
                            type="Password" 
                            autocomplete="off"
                            id="pCode" 
                            class="form-control " 
                            placeholder="Enter card ID" 
                            [(ngModel)]="cardActivateObj.cardNo" 
                            name="cardNo" 
                            (keydown)="onKeydown($event)">
                    </div>
                </div>
                <div class="form-group row ">
                    <div class="col-sm-3 ">
                        <label>Card Balance:</label>
                    </div>
                    <div class="col-sm-9 ">
                        <input 
                            type="number" 
                            class="form-control " 
                            value="0" 
                            id="cBalance"
                            placeholder="0" 
                            [(ngModel)]="cardActivateObj.cardvalue" 
                            name="cardvalue" 
                            disabled>
                    </div>
                </div>
                <div class="form-group row ">
                    <div class="col-sm-3 ">
                        <label>Card TopUp:</label>
                    </div>
                    <div class="col-sm-9 ">
                        <input 
                            type="number" 
                            class="form-control " 
                            value="0" 
                            id="cTopUp"
                            placeholder="Enter card topUp" 
                            [(ngModel)]="cardActivateObj.topUpAmount" 
                            pattern="[1-9][0-9]*" 
                            name="topUpAmount"
                            (keydown)="onKeydownTU($event)">
                            <p *ngIf="tmessage" class="text-warning my-0 " > Enter Top Up Balance for Activation </p>
                    </div>
                </div>
                <!-- <div *ngIf="act" class="form-group row ">
                        <div class="col-sm-3 ">
                            <label>Card Holder:</label>
                        </div>
                        <div class="col-sm-9 ">
                            <input type="text " class="form-control " placeholder="Enter card holder" [(ngModel)]="cardActivateObj.cardHolderName" name="cardHolderName">
                        </div>
                    </div> -->
                <div class="form-group row ">
                    <div class="col-sm-3 ">
                        <label>Card Holder:</label>
                    </div>
                    <div class="col-sm-9 ">
                        <input 
                            type="text " 
                            class="form-control " 
                            id="cHolder"
                            placeholder="Enter card holder" 
                            [(ngModel)]="cardActivateObj.cardHolderName" 
                            name="cardHolderName" 
                            [disabled]="top"
                            (keydown)="onKeydownH($event)">
                    </div>
                </div>
                <!-- <div *ngIf="act" class="form-group row ">
                        <div class="col-sm-3 ">
                            <label>Card Holder Mobile:</label>
                        </div>
                        <div class="col-sm-9 ">
                            <input type="text " class="form-control " placeholder="Enter mobile number" [(ngModel)]="cardActivateObj.cardHolderMobile" name="cardHolderMobile">
                        </div>
                    </div> -->
                <div class="form-group row ">
                    <div class="col-sm-3 ">
                        <label>Card Holder Mobile:</label>
                    </div>
                    <div class="col-sm-9 ">
                        <input 
                            type="text " 
                            id="cHM"
                            class="form-control " 
                            placeholder="Enter mobile number" 
                            [(ngModel)]="cardActivateObj.cardHolderMobile" 
                            name="cardHolderMobile" 
                            [disabled]="top"
                            (keydown)="onKeydownHM($event)" >
                    </div>
                </div>
                <!-- <div *ngIf="act" class="form-group row ">
                        <div class="col-sm-3 ">
                            <label>C. Holder ExtraInfo:</label>
                        </div>
                        <div class="col-sm-9 ">
                            <textarea class="form-control" placeholder="Add any extra info" [(ngModel)]="cardActivateObj.cardHolderExtraInfo" name="cardHolderExtraInfo"></textarea>
                        </div>
                    </div> -->
                <div class="form-group row ">
                    <div class="col-sm-3 ">
                        <label>C. Holder ExtraInfo:</label>
                    </div>
                    <div class="col-sm-9 ">
                        <textarea 
                            id="cHX" 
                            class="form-control" 
                            placeholder="Add any extra info" 
                            [(ngModel)]="cardActivateObj.cardHolderExtraInfo" 
                            name="cardHolderExtraInfo" 
                            [disabled]="top"
                            (keydown)="onKeydownHX($event)" ></textarea>
                    </div>
                </div>
                <div class="form-group row ">
                    <div class="col-sm-3 ">
                        <label>Remarks:</label>
                    </div>
                    <div class="col-sm-9">
                        <textarea 
                            class="form-control"
                            id="cRemarks" 
                            placeholder="Add remarks if any" 
                            [(ngModel)]="cardActivateObj.remarks" 
                            (keydown)="onKeyMarks($event)"
                            name="remarks"></textarea>
                    </div>
                </div>
                <br>
                <div class="form-group row d-flex justify-content-center">
                    <div class="col-sm-6 d-flex justify-content-center">
                        <button class="btn btn-success "
                            *ngIf="mainService.userPrivilages.fonepay" 
                            [disabled]="activationForm.invalid" 
                            (keydown)="requestFonepayQr()" 
                            value="submit " 
                            (click)="requestFonepayQr()">Fonepay</button> &nbsp;&nbsp;
                        <button class="btn btn-success"
                            *ngIf="mainService.userPrivilages.cash"  
                            [disabled]="activationForm.invalid" 
                            (keydown)="onActivate('cash')" 
                            value="submit " 
                            (click)="onActivate('cash')">Cash</button> &nbsp;&nbsp;
                        <!-- <button *ngIf="top " class="btn btn-success " value="submit " (click)="onActivate() ">TopUp</button> &nbsp;&nbsp; -->
                        <button class="btn btn-info " (click)="onClear() ">Clear</button>
                    </div>
                </div>
            </form>

           



        </div>
    </div>
</body>


<div class="overlay " *ngIf="popup ">
    <div class="popup ">
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <label>{{message}}</label>
            </div>
        </div>
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <button 
                    [ngClass]=" isError ? 'btn btn-warning' : 'btn btn-primary'" 
                    (click)="popup=false" 
                    style="margin-left: 180px; ">Ok</button>
            </div>
        </div>

    </div>
</div>


<div class="fonepayOverlay" *ngIf="showFonepay">
    <div class="fonepay">
        <span class="closeFonepay" (click)="closeFonepay()">×</span>
        <span class="trnStatus" [style.color]="trnStatusColor">{{trnStatus}}</span>
        <span class="traceId">{{traceId}}</span>
        <img src="./../../../assets/Fonepay.png" width="300"/>
        <canvas class="qrCode" id="qr"></canvas>

        <a class="text-info mr-3 checkTrnStatus" style="margin-right: 10px;">
            <i class="fa fa-refresh" (click)="checkTrnStatus()"></i>
          </a>
        <!-- <qrcode [qrdata]="'Your QR Code Data Here'"></qrcode> -->
    </div>
</div>




<style>
    body {
        background-image: url('../../../assets/pos.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        height: 100vh;
    }
</style>