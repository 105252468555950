import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { PrintInvoiceComponent } from './print-invoice.component';

@Component({
  selector: 'app-testprint',
   templateUrl: './testprint.component.html',
  //styleUrls: ['./testprint.component.css'],
  providers: [PrintInvoiceComponent]
})
export class TestprintComponent implements OnInit {

  // cardNo: any;
  // name:string;
  // mobile:any;
  // topUp:number;
  // balance: number;
  // myDate = Date.now();
  // constructor(private mainService: MainService,
  //   public invoicePrint: PrintInvoiceComponent)
  //   {

  //  }

  ngOnInit(): void {
  //   this.cardNo = this.mainService.cardObj.cardNo;
  //   this.mobile = this.mainService.cardObj.cardHolderMobile;
  //   this.balance = this.mainService.cardObj.cardvalue;
  //   this.topUp = this.mainService.cardObj.topUpAmount;

  }

  // print(){
  //   setTimeout(() => {
  //     console.log(this.cardNo);
  //   this.invoicePrint.printInvoice(this.cardNo,this.name,this.mobile,this.balance,this.topUp)
  //   }, 2000);
    
  // }
}

