<body>
    <br>
    <div class="container bg-dark text-white">

        <div class="row form-group justify-content-around ">
            <div class="col-sm-2">
                <label>From Date:</label>
                <input type="date" [(ngModel)]="fdate" placeholder="Year Start Date">
            </div>
            <div class="col-sm-2">
                <label>To Date:</label>
                <input type="date" [(ngModel)]="tdate" placeholder="Year Start Date">
            </div>
            <div class="col-sm-3">
                <label>Terminal:</label>
                <select class="form-control">
                    <option> All Terminals </option>
                </select>
            </div>
            <div class="col-sm-3">
                <button class="btn btn-success " (keydown)="loadReport()" (click)="loadReport() ">Load</button>
            </div>
        </div>




        <div class="table-responsive">
            <table class="table table-striped table-dark">
                <thead>
                    <th style="width: 60px;">Sn</th>
                    <th style="width: 150px;">Date</th>
                    <th>Terminal</th>
                    <th style="width: 150px;">Card ID</th>
                    <th>Customer Name</th>
                    <th style="width: 100px;">Mobile</th>
                    <th style="width: 100px;">Sales Amount</th>
                    <th>Remarks</th>
                </thead>
                <tbody>
                    <tr style="height: 28px;" *ngFor="let r of reportList; let i=index">
                        <td style="width: 60px;">{{i}}</td>
                        <td style="width: 150px;">{{r.trnDate}}</td>
                        <td>{{r.terminal}}</td>
                        <td style="width: 150px;">{{r.cardId}}</td>
                        <td>{{r.cardHolderName}}</td>
                        <td style="width: 150px;">{{r.cardHolderMobile}}</td>
                        <td style="width: 200px;">{{r.sales}}</td>
                        <td>{{r.remarks}}</td>
                    </tr>
                </tbody>
            </table>
        </div>



    </div>
</body>

<style>
    body {
        background-image: url('../../../assets/pos.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        height: 100vh;
    }
</style>