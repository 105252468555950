import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardActivationComponent } from './Card/card-activation/card-activation.component';
import { CardRegistrationComponent } from './Card/card-registration/card-registration.component';
import { CardSessionendComponent } from './Card/card-sessionend/card-sessionend.component';
import { SummaryReportComponent } from './Report/summary-report/summary-report.component';
import { SalesReportComponent } from './Report/sales-report/sales-report.component';
import { LoginComponent } from './login/login.component';
import { BodyComponent } from './body/body.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusReportComponent } from './Report/status-report/status-report.component';
import { PrintReportComponent } from './Report/print-report/print-report.component';
import { ActReportComponent } from './Report/act-report/act-report.component';
import { TopReportComponent } from './Report/top-report/top-report.component';
import { TestprintComponent } from './testprint/testprint.component';
import { PrintInvoiceComponent } from './testprint/print-invoice.component';
import { PrintTopComponent } from './testprint/print-top.component';
import { PrintSessionendComponent } from './testprint/print-sessionend.component'; 
import { PSummaryComponent } from './Report/summary-report/pSummary/pSummary.component'
import { AuthGuardService } from './services/auth-guard.service';
import { ChangePasswordComponent } from './changePassword/changePassword.component';
import { JwtInterceptor } from './services/token-interceptor.service';
import { SearchPipe } from './pipe/search.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserListComponent } from './users/userList/userList.component';
import { ScrollHelper } from './services/scroll-helper.service';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { UserRegisterComponent } from './users/user-register/user-register.component';
import { TopupReportComponent } from './Report/topup-report/topup-report.component';
// import { dynamicAPI } from './services/dynamicAPI.services';
// import { TokenInterceptorService } from './services/token-interceptor.service';



@NgModule({
  declarations: [
    AppComponent,
    CardActivationComponent,
    CardRegistrationComponent,
    CardSessionendComponent,
    SummaryReportComponent,
    SalesReportComponent,
    LoginComponent,
    ChangePasswordComponent,
    BodyComponent,
    StatusReportComponent,
    SearchPipe,    
    PrintReportComponent,
    ActReportComponent,
    TopReportComponent,
    TestprintComponent,
    PrintInvoiceComponent,
    PrintTopComponent,
    PrintSessionendComponent,
    PSummaryComponent,
    UserListComponent,
    UserRegisterComponent,
    TopupReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
  ],
  providers: [
    AuthGuardService,
    ScrollHelper,
    { provide: HTTP_INTERCEPTORS , useClass: JwtInterceptor, multi: true }
    // {provide: HTTP_INTERCEPTORS,
    // useClass: dynamicAPI,
    // multi: true}
  ],
  exports: [
    SearchPipe,
    NgxPaginationModule,
    MatSnackBarModule,
    ReactiveFormsModule
  ],
  bootstrap: [AppComponent]
})  
export class AppModule { }


// {
//   provide:HTTP_INTERCEPTORS,
//   useClass:TokenInterceptorService,
//   multi:true //to use multiple interceptors if required
// }