import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment'

@Injectable()

export class dynamicAPI implements HttpInterceptor {

    jsonDataResult: any;

    constructor(private http: HttpClient){
        
        this.http.get('assets/json/data.json').subscribe((res) => {
            this.jsonDataResult = res;
        })
    }

    load(){
        console.log(this.jsonDataResult.apiURL)
        console.log(environment.apiUrl)
        environment.apiUrl = this.jsonDataResult.apiURL;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

        // const baseURL = document.getElementsByTagName('base')[0].href;
        // const apiReq = req.clone({ url: `${baseURL}${req.url}` })

        return next.handle(req);

        // return next.handle(apiReq);
    }
}