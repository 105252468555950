import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from '../interfaces/IMainService';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  popup = false;
  message: string;
  cardLoginObj = <Login>{}

  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private mainService: MainService,
  ) { }

  ngOnInit(): void {
  }

  onLogin(){
    this.mainService.login(this.cardLoginObj).subscribe( data=>{
      const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/body/login';
      this.router.navigate([returnUrl])

    },
    (err) => {
      if( err.status == 400 ){
        console.log('400')
        this.message = 'Username or Password does not match';
        this.popup = true;
      }
    }
    )
   
  }

}
