
<body class=" w-100 my-50 ">
    <div class="row justify-content-center ">
        <div 
        class="card container align-items-center col-12 col-sm-12 col-md-8 col-lg-6 col-xl-5" 
        style="background-color: rgb(72, 7, 133);">
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit($event)">
            <h2 class="card-title text-center my-4" style="color: white;">Change Password</h2>
            <div class="form-group" style="margin-top: 30px;">
                <input type="password" class="form-control" id="oldPassword" placeholder="Old password"
                    formControlName="oldPassword">
                <div class="inputFieldWrapper">
                    <small class="form-text text-danger text-left msgtext"
                        *ngIf="changePasswordForm.controls.oldPassword.invalid && changePasswordForm.controls.oldPassword.touched  && changePasswordForm.controls.oldPassword.errors?.required">
                        Old password is required
                    </small>
                </div>
            </div>
            <div class="form-group" style="margin-top: 30px;">
                <input type="password" class="form-control" id="New password" placeholder="New password"
                    formControlName="newPassword">
                <div class="inputFieldWrapper">
                    <small class="form-text text-danger text-left msgtext"
                        *ngIf="changePasswordForm.controls.newPassword.invalid && changePasswordForm.controls.newPassword.touched">
                        {{getPasswordError()}}
                    </small>
                </div>
            </div>
            <div class="form-group" style="margin-top: 30px;">
                <input type="password" class="form-control" id="newPasswordConfirm" placeholder="Confirm new password"
                    formControlName="newPasswordConfirm">
                <div class="inputFieldWrapper">
                    <small class="form-text text-danger text-left msgtext"
                        *ngIf="changePasswordForm.controls.newPasswordConfirm.invalid && changePasswordForm.controls.newPasswordConfirm.touched">
                        {{getConfirmPasswordError()}}
                    </small>
                </div>
            </div>

            <!-- <div class="error">{{ errorMsg }}</div> -->
            <div class="row">
                <div class="col-lg-12" style="text-align: center;">
                    <button type="submit" class="btn btn-primary">SUBMIT</button>
                </div>
            </div>

        </form>
    </div>
    </div>
</body>


<div class="overlay" *ngIf="popup">
    <div class="popup">
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <label>{{message}}</label>
            </div>
        </div>
        <div class="form-group row ">
            <div class="col-sm-12 ">
                <button class="btn btn-warning" (click)="popup=false " style="margin-left: 180px; ">Ok</button>
            </div>
        </div>

    </div>
</div>

<style>
    body {
        background-image: url('../../../assets/blue.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        background-size: cover; 
        height: 100%;
        width: 100%;
        background-position: center center;
    }
</style>