import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from './services/main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  // title = 'CardPayment';
  

  //CHECKING DROP OPEN
  // isLogin = false;


  // //ELEMENTREF FOR PREVENTING HIDE ON REPORT CLICK
  // //ELEMENTREF FOR PREVENTING HIDE ON REPORT CLICK


  // @ViewChild('noDrop', { static: false })
  // noDrop!: ElementRef;

  // @HostListener('window:click', ['$event']) 
  // handleWindowClick(event: MouseEvent){
  //   if( !(event.target == this.noDrop.nativeElement) )
  //   document.getElementById("myDropDown")?.classList.remove('show');
  // }

  // constructor(
  //   private route: ActivatedRoute,
  //   private router: Router
  // ){}

  // dropFunction(){
    //WHEN USER CLICK REPORTS, TOGGLE BETWEEN SHOW AND HIDE
    // document.getElementById("myDropDown")?.classList.toggle('show');
    // this.isOpen = !this.isOpen;
  //     console.log(this.route)
  // }

  // onLogin(){
  //   this.router.navigate(['body'])
  // }

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private mainService:MainService
  ){ 
    this.httpClient.get('assets/json/data.json').subscribe((res:any) => {
      this.mainService.baseUrl = res.apiURL;
    })
  }

  ngOnInit(): void {
  }

  onLogin(){
    this.router.navigate(['/activation']);    
  }
}
