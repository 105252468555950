import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-act-report',
  templateUrl: './act-report.component.html',
  styleUrls: ['./act-report.component.css']
})
export class ActReportComponent implements OnInit {
 // public childData:string;
  cardNo: any;
  mobile:any;
  topUp:number;
  myDate = Date.now();
  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    this.cardNo = this.mainService.cardObj.cardNo;
    this.mobile = this.mainService.cardObj.cardHolderMobile;
    this.topUp = this.mainService.cardObj.topUpAmount;

    setTimeout(() => {
      window.print()  
    }, 2000);
  }
  
}
